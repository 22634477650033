<template>
  <div
    class="app-calendar-row"
    :class="{ 'app-calendar-row--past': past }"
  >
    <school-calendar-row-col
      v-for="(day, key) in [0,1,2,3,4,5,6]"
      :key="startDate.clone().add(key, 'days').format('DDMMYYYY')"
      :day="days[day]"
      :loading-row="loadingRow"
      :user-id="userId"
      :current="current"
      :date="startDate.clone().add(key, 'days')"
      :class="{ 'noSummary': !showSummary }"
    />

    <school-calendar-row-summary
      v-if="showSummary"
      :sum-values="summaryValues"
      :loading-row="loadingRow"
    >
      <template #header>
        <span :class="{ 'font-weight-bold': current }">
          {{ `${startDate.clone().format("DD.MM")} – ${startDate.clone().add(6, 'days').format("DD.MM")}` }}
        </span>
      </template>
    </school-calendar-row-summary>
  </div>
</template>

<script>
  import SchoolCalendarRowCol from './SchoolCalendarRowCol.vue';
  import SchoolCalendarRowSummary from './SchoolCalendarRowSummary.vue';
  import { getSummaryData } from '@/helpers/move';

  export default {
    name: 'SchoolCalendarRow',

    props: {
      userId: {
        type: [Number],
        default: null,
      },

      startDate: {
        type: [Object, Number],
        default: null,
      },

      days: {
        type: [Array],
        default: () => ([]),
      },

      current: {
        type: [Boolean],
        default: false,
      },

      past: {
        type: [Boolean],
        default: false,
      },

      loadingRow: {
        type: [Boolean],
        default: false,
      },
    },

    components: {
      SchoolCalendarRowCol,
      SchoolCalendarRowSummary,
    },

    computed: {
      showSummary() {
        return this.$store.getters['user/getShowSummary'];
      },

      summaryValues() {
        return getSummaryData(this.days);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/styles/components/calendarRow';

  @include calendarRowCommon;
</style>
