<template>
  <div class="app-calendar-column">
    <slot>
      <div class="app-calendar-column__wrapper">
        <div
          class="app-calendar-column__header text-caption"
          :class="currentDay ? 'app-calendar-column__header--current' : ''"
        >
          <div class="d-flex align-center w-100">
            <v-icon
              v-if="currentDay"
              color="white"
              small
              style="margin-left:-4px;"
            >
              mdi-chevron-right
            </v-icon>
            <span :class="{ 'font-weight-bold': currentWeek }">
              {{ date.format('DD MMM') }}
            </span>
          </div>
        </div>
      </div>

      <div class="app-calendar-column__body">
        <slot name="body">
          <div v-if="!loadingRow">
            <div class="overflow-x-hidden">
              <div class="mb-1">
                <v-textarea
                  v-if="!textareaDisabled"
                  v-model="compDescription"
                  outlined
                  placeholder="Добавьте план"
                  dense
                  auto-grow
                  hide-details
                  row-height="12"
                  rows="7"
                  tile
                  class="text-caption lh-1 lh-child-1 mb-1 ko-style-textarea"
                />

                <div
                  v-else-if="compDescription && compDescription !== ''"
                  class="app-calendar-plan text-caption"
                >
                  <div class="app-calendar-plan__header">
                    <div class="app-calendar-plan__title">
                      План
                    </div>
                  </div>

                  <div v-html="nl2br(compDescription)" />
                </div>

                <div class="d-flex justify-end mb-2">
                  <v-btn
                    v-if="textareaDisabled"
                    class="ml-auto"
                    x-small
                    outlined
                    color="icon"
                    rounded
                    :disabled="isLoading"
                    @click="editPlan"
                  >
                    {{ (compDescription && compDescription !== '') ? 'Править' : 'Добавить план' }}
                  </v-btn>

                  <v-btn
                    v-else
                    class="ml-auto"
                    x-small
                    outlined
                    color="success"
                    rounded
                    :disabled="isLoading"
                    @click="savePlan"
                  >
                    Сохранить
                  </v-btn>
                </div>
              </div>
            </div>

            <div v-if="day.races.length > 0">
              <school-calendar-row-col-event
                v-for="race in day.races"
                :key="race.id"
                :item="race"
              />
            </div>

            <div v-if="getShowMoves || localShowData">
              <school-calendar-row-move
                v-for="(item, key) in day.moves"
                :key="key"
                :item="item"
                type="move"
              />
            </div>

            <div
              v-if="(!getShowMoves && day.moves.length > 0) && (!localShowData)"
              class="px-1 pt-1 pb-3"
            >
              <div class="text-caption lh-1">
                <span
                  class="c-pointer"
                  @click="localShowData = true"
                >{{ compCountMoves }}</span>
              </div>
            </div>
          </div>

          <div v-else>
            <v-skeleton-loader
              type="image"
              max-height="92"
              class="mb-1"
            />
          </div>
        </slot>
      </div>
    </slot>
  </div>
</template>

<script>
  import SchoolCalendarRowMove from './SchoolCalendarRowMove.vue';
  import SchoolCalendarRowColEvent from './SchoolCalendarRowColEvent.vue';
  import { getNoun, nl2br } from '@/helpers/string';
  import { createOrUpdatePlan } from '@/api/plan';

  export default {
    name: 'SchoolCalendarRowCol',

    props: {
      userId: {
        type: [Number],
        default: null,
      },

      day: {
        type: [Object],
        default: () => ({
          moves: [],
          plans: [],
          races: [],
        }),
      },

      date: {
        type: [Object],
        default: null,
      },

      current: {
        type: [Boolean],
        default: false,
      },

      loadingRow: {
        type: [Boolean],
        default: false,
      },
    },

    components: {
      SchoolCalendarRowColEvent,
      SchoolCalendarRowMove,
    },

    data: () => ({
      localPlan: {
        description: '',
        id: -1,
        set: false,
      },
      isLoading: false,
      isDeletingRace: false,
      textareaDisabled: true,
      localDescription: '',
      localShowData: false,
      descriptionOpen: false,
    }),

    computed: {
      getShowPlans() {
        return this.$store.getters['user/getShowPlans'];
      },

      getShowMoves() {
        return this.$store.getters['user/getShowMoves'];
      },

      compPlans() {
        const plans = [];

        this.day.plans.forEach((plan) => {
          if (plan.description && plan.description !== '') {
            plans.push(plan);
          }
        });

        return plans;
      },

      compDescription: {
        get() {
          return this.day.plans.length > 0
            ? this.day.plans[0].description
            : this.localPlan.description;
        },

        set(newValue) {
          if (this.day.plans.length > 0) {
            this.day.plans[0].description = newValue;
          } else {
            this.localPlan.description = newValue;
          }
        },
      },

      compCountPlans() {
        return `${this.day.plans.length} ${getNoun(['план', 'плана', 'планов'], this.day.plans.length)}`;
      },

      compCountMoves() {
        return `${this.day.moves.length} ${getNoun(['тренировка', 'тренировки', 'тренировок'], this.day.moves.length)}`;
      },

      currentWeek() {
        return this.current;
      },

      currentDay() {
        const now = this.$moment();

        const startOfDay = this.date.clone();
        const endOfDay = this.date.clone().add(1, 'days');

        return startOfDay <= now
          && endOfDay > now;
      },
    },

    methods: {
      nl2br,

      editPlan() {
        this.textareaDisabled = false;
      },

      savePlan() {
        this.isLoading = true;

        const data = {
          user_id: this.userId,
          description: this.day.plans.length > 0
            ? this.day.plans[0].description
            : this.localPlan.description,
          start: `${this.date.format('YYYY-MM-DD')} 00:00:00`,
          id: this.day.plans.length > 0
            ? this.day.plans[0].id
            : this.localPlan.id,
        };

        createOrUpdatePlan(data)
          .then((res) => {
            if (this.day.plans.length > 0) {
              this.day.plans[0].description = res.data.description;
            } else {
              // план сохраняется в массив, нужно как-то избавиться от локалПлан
              this.localPlan.description = res.data.description;
              this.localPlan.id = res.data.id;
              this.day.plans.push(this.localPlan);
            }
          })
          .finally(() => {
            this.isLoading = false;
            this.textareaDisabled = true;
          });
      },
    },

    watch: {
      getShowPlans() {
        this.localShowData = false;
      },

      getShowMoves() {
        this.localShowData = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/styles/components/calendarColumn';
  @import '@/styles/components/calendarPlan';

  @include calendarColumnCommon;
  @include calendarPlanCommon;

  .app-calendar-plan {
    margin-bottom: 4px;
  }
</style>
