<template>
  <div class="app-calendar-move">
    <div class="app-calendar-move__content">
      <div class="app-calendar-move__header">
        <v-icon
          color="black"
          size="20"
        >
          {{ iconName }}
        </v-icon>
      </div>

      <div class="text-caption font-weight-bold lh-1 my-2">
        <a
          v-if="stravaLinkComp !== '#'"
          :href="stravaLinkComp"
          target="_blank"
        >
          {{ item.name }}
        </a>

        <span v-else>
          {{ item.name }}
        </span>
      </div>

      <div
        v-if="distComp !== 0"
        class="lh-1"
      >
        <v-icon
          color="black"
          small
        >
          mdi-vector-line
        </v-icon>

        <span class="text-caption">
          {{ distComp + ' км' }}
        </span>
      </div>

      <div class="lh-1">
        <v-icon
          color="black"
          small
        >
          mdi-timer-outline
        </v-icon>

        <span class="text-caption">
          {{ timeComp }}
        </span>
      </div>

      <div
        v-if="elevComp !== 0"
        class="lh-1"
      >
        <v-icon
          color="black"
          small
        >
          mdi-trending-up
        </v-icon>

        <span class="text-caption">
          {{ elevComp + ' м' }}
        </span>
      </div>

      <div
        v-if="heartrateComp !== '-'"
        class="lh-1"
      >
        <v-icon
          color="black"
          small
        >
          mdi-heart-outline
        </v-icon>

        <span class="text-caption">
          {{ heartrateComp }}
        </span>
      </div>

      <div
        v-if="enableLaps && lapsComp && lapsComp.length > 1"
        class="lh-1"
      >
        <v-expansion-panels
          flat
          class="app-calendar-move__laps"
        >
          <v-expansion-panel>
            <v-expansion-panel-header
              class="text-caption font-weight-bold py-2 px-0"
            >
              Круги
            </v-expansion-panel-header>

            <v-expansion-panel-content class="overflow-x-visible">
              <v-simple-table dense>
                <thead>
                  <tr class="text-caption">
                    <th>
                      <v-icon
                        color="black"
                        small
                      >
                        mdi-vector-line
                      </v-icon>
                    </th>

                    <th>
                      <v-icon
                        color="black"
                        small
                      >
                        mdi-timer-outline
                      </v-icon>
                    </th>

                    <th>
                      <v-icon
                        color="black"
                        small
                      >
                        mdi-speedometer
                      </v-icon>
                    </th>

                    <th>
                      <v-icon
                        color="black"
                        small
                      >
                        mdi-trending-up
                      </v-icon>
                    </th>

                    <th>
                      <v-icon
                        color="black"
                        small
                      >
                        mdi-heart-outline
                      </v-icon>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(lap, key) in lapsComp"
                    :key="key"
                    class="text-caption"
                  >
                    <td>
                      {{ Math.trunc(lap.distance / 10) / 100 }}
                    </td>
                    <td>
                      {{ convertSecondsToHMMSS(lap.elapsed_time, false) }}
                    </td>
                    <td>
                      {{ convertSecondsToHMMSS(Math.trunc(lap.elapsed_time / (lap.distance / 1000)), false) }}
                    </td>
                    <td>
                      {{ lap.total_elevation_gain }}
                    </td>
                    <td>
                      {{ lap.average_heartrate ? Math.trunc(lap.average_heartrate) : "-" }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
  import { convertSecondsToHMMSS, getMoveIconName } from '@/helpers/move';

  export default {
    name: 'SchoolCalendarRowMove',

    props: {
      item: {
        type: [Object],
        default: null,
      },

      type: {
        type: [String],
        default: 'move',
      },

      enableLaps: {
        type: [Boolean],
        default: true,
      },
    },

    data: () => ({
      isDeletingMove: false,
      isHidingMove: false,
    }),

    computed: {
      iconName() {
        return getMoveIconName(this.item?.type);
      },

      timeComp() {
        return this.item
          ? this.convertSecondsToHMMSS(this.item.elapsed_time)
          : 0;
      },

      distComp() {
        return this.item
          ? Math.trunc(this.item.distance / 100) / 10
          : 0;
      },

      elevComp() {
        return this.item
          ? this.item.total_elevation_gain
          : 0;
      },

      lapsComp() {
        return this.item
          ? JSON.parse(this.item.laps)
          : [];
      },

      stravaLinkComp() {
        return this.item
          ? `https://strava.com/activities/${this.item.strava_id}`
          : '#';
      },

      heartrateComp() {
        let heartrate_sum = 0;

        if (this.item?.average_heartrate) {
          return this.item.average_heartrate;
        }

        this.lapsComp?.forEach((lap) => {
          if (lap.average_heartrate) {
            heartrate_sum += lap.average_heartrate * lap.elapsed_time;
          }
        });

        return heartrate_sum > 0
          ? Math.trunc(heartrate_sum / this.item.elapsed_time)
          : '-';
      },
    },

    methods: {
      convertSecondsToHMMSS,
    },
  };
</script>

<style scoped lang="scss">
  @import '@/styles/components/calendarMove';

  @include calendarMoveCommon;

  .app-calendar-move__laps {
    z-index: inherit;

    ::v-deep {
      .v-expansion-panel-header {
        min-height: 20px;
      }

      .v-data-table {
        min-width: 250px;
        margin: 0 -24px;
        border: 1px solid #ccc;
        position: relative;
        z-index: 1;

        td, th {
          padding: 0 4px;
          height: auto;
          text-align: center!important;
        }

        th {
          padding: 2px 4px;
        }

        tbody {
          tr:nth-of-type(2n + 1) {
            background: #f0f0f0;
          }
        }
      }
    }
  }
</style>
