<template>
  <div class="app-calendar-event">
    <div class="app-calendar-event__header">
      <div class="app-calendar-event__title text-caption">
        Событие
      </div>
    </div>

    <div
      v-if="item.name"
      class="app-calendar-event__name"
    >
      <a
        v-if="item.link"
        :href="item.link"
        target="_blank"
        class="text-caption"
      >
        {{ item.name }}
      </a>

      <span
        v-else
        class="text-caption"
      >
        {{ item.name }}
      </span>
    </div>

    <div
      v-if="item.distance"
      class="lh-1 text-caption mt-2"
    >
      {{ item.distance + ' км' }}
    </div>

    <div
      v-if="item.description"
      class="d-flex align-center c-pointer mb-1 mt-2"
      @click="descriptionOpen = !descriptionOpen"
    >
      <span class="text-caption text-decoration-underline lh-1">
        Комментарий
      </span>

      <v-icon
        color="black"
        size="20"
        class="ml-auto"
      >
        {{ descriptionOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
      </v-icon>
    </div>

    <div
      v-if="item.description && descriptionOpen"
      class="text-caption"
      v-html="description"
    />
  </div>
</template>

<script>
  import { nl2br } from '@/helpers/string';

  export default {
    name: 'SchoolCalendarRowColEvent',

    props: {
      item: {
        type: [Object],
        default: null,
      },
    },

    data: () => ({
      descriptionOpen: false,
      isDeletingRace: false,
    }),

    computed: {
      description() {
        return nl2br(this.item.description);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/styles/components/calendarEvent';
  @include calendarEventCommon;
</style>
