<template>
  <app-page-primary
    :is-loading="isLoading"
    :limited-height="false"
    :body-overflow="true"
  >
    <template>
      <div
        style="max-width: 320px;"
        class="mx-auto col pt-0 d-flex"
      >
        <div
          class="d-flex align-center c-pointer"
          @click="$router.push({ name: 'school.members' })"
        >
          <v-btn
            small
            outlined
            color="icon"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>

          <div class="text-decoration-underline px-2">
            Назад
          </div>
        </div>
      </div>

      <div
        v-if="user"
        style="max-width: 320px;"
        class="mx-auto col pt-0"
      >
        <v-row>
          <v-col class="shrink">
            <div class="app-avatar d-flex">
              <v-avatar
                color="#eeeeee"
                size="80"
              >
                <img
                  v-if="user.avatar"
                  :src="user.avatar"
                  alt="image"
                >

                <v-icon
                  v-else
                  size="60"
                  color="black"
                >
                  mdi-account
                </v-icon>
              </v-avatar>
            </div>
          </v-col>

          <v-col class="pl-0">
            <div class="font-weight-medium lh-12 text-break mt-2">
              {{ user.name }}
            </div>

            <div
              v-if="user.birthday || user.city"
              class="text-caption lh-12 mt-1 mb-auto"
            >
              {{ compUserAge }}{{ user.birthday && user.city ? ', ' : '' }}{{ user.city ? user.city : '' }}
            </div>

            <div class="d-flex mt-2">
              <div
                v-if="user.strava_user && user.strava_user.strava_id"
              >
                <a
                  :href="`https://strava.com/athletes/${user.strava_user.strava_id}`"
                  target="_blank"
                >
                  <v-img
                    :src="require('@/assets/logo-strava-small.svg')"
                    width="22"
                    height="22"
                    style="border-radius:4px;"
                    contain
                  />
                </a>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col>
            <div>
              <div class="d-flex align-center mb-3">
                <div class="caption mr-1">
                  События
                </div>

                <v-divider />
              </div>

              <school-member-event
                v-for="race in compFutureRaces"
                :key="race.id"
                :item="race"
              />

              <div
                v-if="compPastRaces.length > 0"
                class="c-pointer text-decoration-underline my-1 caption"
                @click="showPastRaces = !showPastRaces"
              >
                {{ showPastRaces ? 'Скрыть прошедшие' : 'Показать прошедшие' }}
              </div>

              <div v-if="showPastRaces">
                <school-member-event
                  v-for="race in compPastRaces"
                  :key="race.id"
                  :item="race"
                  class="app-past-card"
                />
              </div>

              <div
                v-if="user && user.races.length === 0"
                class="caption my-1"
              >
                Список пуст
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </template>
  </app-page-primary>
</template>

<script>
  import moment from 'moment-timezone';
  import AppPagePrimary from '@/_components/AppPagePrimary.vue';
  import SchoolMemberEvent from '@/_components/school/SchoolMemberEvent.vue';
  import { getNoun } from '@/helpers/string';
  import { getMember } from '@/api/member';

  export default {
    props: {
      id: {
        type: [String, Number],
        default: null,
      },
    },

    components: {
      SchoolMemberEvent,
      AppPagePrimary,
    },

    mounted() {
      this.fetchMember();
    },

    data: () => ({
      moment,
      user: null,
      showPastRaces: false,
      isLoading: false,
    }),

    computed: {
      compUserAge() {
        if (!this.user.birthday) {
          return '';
        }

        const age = this.moment().diff(this.user.birthday, 'years');

        return `${age} ${getNoun(['год', 'года', 'лет'], age)}`;
      },

      compFutureRaces() {
        if (!this.user?.races) {
          return [];
        }

        const races = this.user.races.filter((item) => moment(item.start_date) >= moment().startOf('day'));

        return races.sort((a, b) => {
          if (moment(a.start_date) > moment(b.start_date)) {
            return 1;
          }

          if (moment(a.start_date) < moment(b.start_date)) {
            return -1;
          }

          return 0;
        });
      },

      compPastRaces() {
        if (!this.user?.races) {
          return [];
        }

        const races = this.user.races.filter((item) => moment(item.start_date) < moment().startOf('day'));

        return races.sort((a, b) => {
          if (moment(a.start_date) > moment(b.start_date)) {
            return -1;
          }

          if (moment(a.start_date) < moment(b.start_date)) {
            return 1;
          }

          return 0;
        });
      },
    },

    methods: {
      fetchMember() {
        this.isLoading = true;

        getMember(this.id)
          .then((response) => {
            this.user = response.data;
          })
          .catch(() => {
            this.$router.push({ name: 'school.members' });
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .my-races-list {
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }

  .pulse-table {
    .row {
      &:first-child {
        .col {
          border-top: 1px solid var(--v-black-base);
        }
      }
      .col {
        border-right: 1px solid var(--v-black-base);
        border-bottom: 1px solid var(--v-black-base);

        &:first-child {
          border-left: 1px solid var(--v-black-base);
        }
      }
    }
  }

  .app-past-card {
    opacity: 0.3;
  }

  .app-calendar-race {
    border: 1px solid var(--v-black-base);
    background: var(--v-secondary-base);
    word-break: break-word;
  }
</style>
