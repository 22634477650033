<template>
  <app-page-primary
    :is-loading="fetchCount > 0"
  >
    <template #header>
      <v-btn
        small
        outlined
        color="icon"
        class="mr-1"
        @click="$router.push({ name: 'school.members' })"
      >
        <v-icon
          @click="$router.push({ name: 'school.members' })"
        >
          mdi-chevron-left
        </v-icon>
      </v-btn>

      <div
        class="school-calendar--user--name text-h6"
        :class="{ 'lh-1': $vuetify.breakpoint.xsOnly }"
        @click="openUserProfile"
      >
        {{ user.name }}
      </div>

      <v-spacer />

      <div class="d-flex">
        <v-btn
          class="mr-2"
          color="icon"
          small
          outlined
          :disabled="!enableRefresh"
          @click="refreshCalendar"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>

        <v-menu
          bottom
          offset-y
          left
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-btn
              outlined
              color="icon"
              small
              v-on="on"
            >
              <v-icon>mdi-tune</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item>
              <v-list-item-title>
                <v-checkbox
                  v-model="showPlans"
                  label="Показать планы"
                  dense
                  hide-details=""
                  class="mt-0"
                />
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <v-checkbox
                  v-model="showMoves"
                  label="Показать тренировки"
                  dense
                  hide-details=""
                  class="mt-0"
                />
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <v-checkbox
                  v-model="showSummary"
                  label="Показать сводку"
                  dense
                  hide-details=""
                  class="mt-0"
                />
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>

    <template #default>
      <div class="h-100 w-100">
        <div
          ref="infiniteScroll"
          class="h-100 w-100 overflow-x-auto overflow-y-auto"
          @scroll="debounceInfiniteScroll"
        >
          <div>
            <school-calendar-header :class="{ 'noSummary': !showSummary }" />

            <div ref="infiniteScrollInner">
              <school-calendar-row
                v-for="(item) in calendarRows"
                :key="item.start"
                :start-date="getStartDate(item.start)"
                :days="item.days"
                :loading-row="item.loading"
                :user-id="user.id"
                :current="item.start === 0"
                :past="item.start < 0"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-page-primary>
</template>

<script>
  import SchoolCalendarRow from '@/_components/school/SchoolCalendarRow.vue';
  import SchoolCalendarHeader from '@/_components/school/SchoolCalendarHeader.vue';
  import AppPagePrimary from '@/_components/AppPagePrimary.vue';
  import { getMemberCalendar } from '@/api/member';

  export default {
    name: 'MemberCalendar',

    props: {
      id: {
        type: [Number, String],
        default: -1,
      },
    },

    components: {
      AppPagePrimary,
      SchoolCalendarHeader,
      SchoolCalendarRow,
    },

    created() {
      this.calcRowMaximum();
      window.addEventListener('resize', this.calcRowMaximum);
    },

    destroyed() {
      window.removeEventListener('resize', this.calcRowMaximum);
    },

    mounted() {
      this.addSmth();

      if (localStorage.showPlans) {
        this.showPlans = (localStorage.showPlans === 'true');
      }

      if (localStorage.showMoves) {
        this.showMoves = (localStorage.showMoves === 'true');
      }

      if (localStorage.showSummary) {
        this.showSummary = (localStorage.showSummary === 'true');
      }
    },

    data: () => ({
      enableRefresh: true,
      fetchCount: 0,
      calendarRows: [],
      debug: 0,
      user: {
        name: '',
      },
      redirected: false,
      compRowsMaximum: 8,
      infiniteTimeout: null,
    }),

    computed: {
      showPlans: {
        get() {
          return this.$store.getters['user/getShowPlans'];
        },

        set(val) {
          this.$store.dispatch('user/setShowPlans', val);
        },
      },

      showMoves: {
        get() {
          return this.$store.getters['user/getShowMoves'];
        },

        set(val) {
          this.$store.dispatch('user/setShowMoves', val);
        },
      },

      showSummary: {
        get() {
          return this.$store.getters['user/getShowSummary'];
        },

        set(val) {
          this.$store.dispatch('user/setShowSummary', val);
        },
      },
    },

    methods: {
      openUserProfile() {
        this.$router.push({ name: 'school.member', params: { id: this.user.id } });
      },

      getStartDate(weeks) {
        return this.$moment().startOf('isoweek').add(weeks, 'weeks');
      },

      refreshCalendar() {
        this.enableRefresh = false;
        this.calendarRows = [];
        this.addSmth();
        const that = this;

        setTimeout(() => {
          that.enableRefresh = true;
        }, 5000);
      },

      calcRowMaximum() {
        this.compRowsMaximum = Math.ceil(window.innerHeight / 127 / 4) * 4 + 4;
      },

      addSmth() {
        this.infiniteScroll();

        this.$nextTick(function () {
          if ((this.$refs.infiniteScroll.offsetHeight > this.$refs.infiniteScrollInner.offsetHeight && this.calendarRows.length < this.compRowsMaximum) || (this.$refs.infiniteScroll.scrollTop === 0)) {
            this.addSmth();
          }
        });
      },

      addRows(prepend = false) {
        this.fetchCount++;

        let first = 0;
        if (this.calendarRows.length !== 0) {
          if (prepend) {
            first = this.calendarRows[0].start - 4;
          } else {
            first = this.calendarRows[this.calendarRows.length - 1].start + 1;
          }
        }

        for (let i = 0; i < 4; i++) {
          const row = {
            start: 0,
            days: [],
            loading: true,
          };

          if (this.calendarRows.length !== 0) {
            if (prepend) {
              row.start = this.calendarRows[0].start - 1;
              this.calendarRows.unshift(row);
            } else {
              row.start = this.calendarRows[this.calendarRows.length - 1].start + 1;
              this.calendarRows.push(row);
            }
          } else {
            this.calendarRows.push(row);
          }
        }

        const params = {
          zone: this.$moment.tz.guess(),
        };

        getMemberCalendar(this.id, first, params)
          .then((res) => {
            this.user = res.data.user;

            res.data.weeks.forEach((week) => {
              const row = this.calendarRows.find((item) => item.start === week.start);
              row.days = week.days;
              row.loading = false;
            });
          })
          .catch(() => {
            if (this.redirected) {
              return;
            }

            this.redirected = true;
            this.$router.push({ name: 'school.members' });
          })
          .finally(() => {
            this.fetchCount--;
          });
      },

      infiniteScroll(that = this) {
        const scrollTop = that.$refs.infiniteScroll.scrollTop;
        const lastHeight = that.$refs.infiniteScroll.scrollHeight;

        const bottomOfWindow = scrollTop + that.$refs.infiniteScroll.clientHeight >= that.$refs.infiniteScroll.scrollHeight - 5;

        if (bottomOfWindow) {
          that.addRows();
        } else if (scrollTop <= 5) {
          that.addRows(true);

          that.$nextTick(() => {
            const newHeight = that.$refs.infiniteScroll.scrollHeight;
            that.$refs.infiniteScroll.scrollTop = (newHeight - lastHeight);

            that.$nextTick(() => {
              while (that.calendarRows.length > that.compRowsMaximum) {
                that.calendarRows.splice(-4, 4);
              }
            });
          });
        }
      },

      debounceInfiniteScroll() {
        clearTimeout(this.infiniteTimeout);

        const that = this;

        this.infiniteTimeout = setTimeout(() => {
          that.infiniteScroll(that);
        }, 100);
      },
    },
  };
</script>

<style scoped lang="scss">
  .school-calendar--user--name {
    font-size: 1rem!important;
    min-height: 100%;
    word-break: break-word;
    margin: 0 4px;
    display: flex;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;
    line-height: 1;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      font-size: 1.25rem!important;
      line-height: 2rem;
    }
  }
</style>
