import axios from 'axios';

export const getMembersWithWeekCalendars = function (week, params) {
  const url = `/api/school/members/${week}`;

  return axios.get(url, {
    params,
  });
};

export const getMember = function (id) {
  const url = `/api/school/member/${id}`;

  return axios.get(url);
};

export const getMemberCalendar = function (id, firstWeek, params) {
  const url = `/api/school/member/${id}/calendar/${firstWeek}`;

  return axios.get(url, {
    params,
  });
};
