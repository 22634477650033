<template>
  <app-page-primary
    :is-loading="isLoading"
  >
    <template #header>
      <div class="text-h6">
        Школа
      </div>

      <v-spacer />

      <div class="d-flex">
        <v-btn
          class="mr-2"
          color="icon"
          small
          outlined
          :disabled="!enableRefresh"
          @click="refreshDashboard"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>

        <v-btn
          class="mr-2"
          color="icon"
          small
          outlined
          @click="week--"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-btn
          class="mr-2"
          color="icon"
          small
          outlined
          @click="week++"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>

        <v-menu
          bottom
          offset-y
          left
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-btn
              color="icon"
              small
              outlined
              v-on="on"
            >
              <v-icon>mdi-tune</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item>
              <v-list-item-title>
                <v-checkbox
                  v-model="showPlans"
                  label="Показать планы"
                  dense
                  hide-details=""
                  :ripple="false"
                  class="mt-0"
                />
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <v-checkbox
                  v-model="showMoves"
                  label="Показать тренировки"
                  dense
                  hide-details=""
                  :ripple="false"
                  class="mt-0"
                />
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <v-checkbox
                  v-model="showSummary"
                  label="Показать сводку"
                  dense
                  hide-details=""
                  class="mt-0"
                />
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>

    <template #default>
      <div class="dashboard-current-week pl-2 pb-2 text-caption font-weight-bold">
        {{ $moment().startOf('isoweek').add(week, 'weeks').format('DD MMMM') }} &mdash; {{ $moment().endOf('isoweek').add(week, 'weeks').format('DD MMMM') }} ({{ week === 0 ? 'текущая' : week > 0 ? `+${week}` : `${week}` }})
      </div>

      <div
        :key="keyDashboard"
        class="h-100 w-100 pt-5"
      >
        <div
          id="school-dashboard-scroll"
          class="h-100 w-100 app-calendar member-calendar overflow-x-auto"
          :class="{ 'no-summary': !showSummary }"
        >
          <div>
            <div
              v-for="(user, key) in users"
              :id="`user-${user.id}`"
              :key="key"
              class="dashboard-panel"
            >
              <div
                class="dashboard-user d-flex"
                :class="$vuetify.breakpoint.lgAndUp && drawer ? 'dashboard-user--dense' : ''"
              >
                <div class="d-flex align-center">
                  <div
                    class="member-info py-1 px-2 font-weight-black d-flex align-center text-caption c-pointer flex-grow-1"
                    @click="toggleUserPanel(user, key)"
                  >
                    <v-avatar
                      size="32"
                      class="mr-2"
                      color="#eeeeee"
                      @click="openUserProfile(user)"
                    >
                      <v-img
                        v-if="user.avatar"
                        :src="user.avatar"
                      />

                      <v-icon
                        v-else
                        color="black"
                      >
                        mdi-account
                      </v-icon>
                    </v-avatar>

                    <div>
                      <div class="lh-1 mb-1">
                        {{ user.name }}
                      </div>

                      <div class="lh-1 font-weight-regular">
                        Планов:
                        <span
                          class="font-weight-bold"
                          :class="user.plans_count === 0 && !isLoading ? 'error--text' : user.plans_count >= 5 ? 'success--text' : ''"
                        >
                          {{ isLoading ? '?' : user.plans_count }}
                        </span>
                      </div>
                    </div>

                    <v-btn
                      icon
                      small
                      class="ml-auto"
                    >
                      <v-icon
                        size="20"
                        color="icon"
                      >
                        {{ openPanel[key] ? 'mdi-minus' : 'mdi-plus' }}
                      </v-icon>
                    </v-btn>
                  </div>

                  <div class="ml-3 mr-5">
                    <v-btn
                      icon
                      small
                      @click="$router.push({ name: 'school.member.calendar', params: { id: user.id } })"
                    >
                      <v-icon
                        size="20"
                        color="icon"
                      >
                        mdi-open-in-new
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>

              <school-calendar-header
                v-if="user.open"
              />

              <school-calendar-row
                v-if="user.open"
                :start-date="$moment().startOf('isoweek').add(week, 'weeks')"
                :days="user.days"
                :loading-row="isLoading"
                :user-id="user.id"
                :current="week===0"
                :class="week < 0 ? 'app-calendar-row-past' : ''"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-page-primary>
</template>

<script>
  import SchoolCalendarRow from '@/_components/school/SchoolCalendarRow.vue';
  import SchoolCalendarHeader from '@/_components/school/SchoolCalendarHeader.vue';
  import AppPagePrimary from '@/_components/AppPagePrimary.vue';
  import { getMembersWithWeekCalendars } from '@/api/member';

  export default {
    name: 'Dashboard',

    props: {
      drawer: {
        type: [Boolean],
        default: null,
      },
    },

    components: {
      AppPagePrimary,
      SchoolCalendarHeader,
      SchoolCalendarRow,
    },

    mounted() {
      this.getUsers();

      if (localStorage.showPlans) {
        this.showPlans = (localStorage.showPlans === 'true');
      }

      if (localStorage.showMoves) {
        this.showMoves = (localStorage.showMoves === 'true');
      }

      if (localStorage.showSummary) {
        this.showSummary = (localStorage.showSummary === 'true');
      }
    },

    data: () => ({
      enableRefresh: true,
      keyDashboard: 0,
      isLoading: false,
      week: 0,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Имя',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'E-mail',
          align: 'start',
          sortable: false,
          value: 'email',
        },
        {
          text: 'Дата регистрации',
          align: 'start',
          sortable: false,
          value: 'created_at',
        },
        {
          text: 'Тренировки',
          value: 'moves',
        },
      ],
      has_error: false,
      users: [],
      openPanel: [],
    }),

    computed: {
      showPlans: {
        get() {
          return this.$store.getters['user/getShowPlans'];
        },

        set(val) {
          this.$store.dispatch('user/setShowPlans', val);
        },
      },

      showMoves: {
        get() {
          return this.$store.getters['user/getShowMoves'];
        },

        set(val) {
          this.$store.dispatch('user/setShowMoves', val);
        },
      },

      showSummary: {
        get() {
          return this.$store.getters['user/getShowSummary'];
        },

        set(val) {
          this.$store.dispatch('user/setShowSummary', val);
        },
      },
    },

    methods: {
      refreshDashboard() {
        this.enableRefresh = false;
        this.getUsers();
        const that = this;

        setTimeout(() => {
          that.enableRefresh = true;
        }, 5000);
      },

      openUserProfile(user) {
        this.$router.push({ name: 'school.member', params: { id: user.id } });
      },

      toggleUserPanel(user, key) {
        this.openPanel[key] = !this.openPanel[key];
        user.open = !user.open;

        this.$nextTick(() => {
          if (this.openPanel[key]) {
            return;
          }

          // при закрытии пролистать до верха блока, если он выше границы видимости
          try {
            if (document.getElementById(`user-${user.id}`).offsetTop < document.getElementById('school-dashboard-scroll').scrollTop) {
              document.getElementById('school-dashboard-scroll').scrollTop = document.getElementById(`user-${user.id}`).offsetTop - document.getElementById('school-dashboard-scroll').offsetTop;
            }
          } catch (e) {
            //
          }
        });
      },

      getUsers() {
        this.keyDashboard += 1;
        this.isLoading = true;

        const params = {
          zone: this.$moment.tz.guess(),
        };

        getMembersWithWeekCalendars(this.week, params)
          .then((res) => {
            this.users = res.data.users;

            if (this.users.length !== this.openPanel.length) {
              this.openPanel = [];

              this.users.forEach((item) => {
                this.openPanel.push(false);
                this.$set(item, 'open', false);
              });
            } else {
              this.users.forEach((item, key) => {
                this.$set(item, 'open', this.openPanel[key]);
              });
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },

    watch: {
      week() {
        this.getUsers();
      },
    },
  };
</script>

<style scoped lang="scss">
  .app-calendar {
    ::v-deep {
      .app-calendar-header-col {
        width: $app-calendar-col-width;
      }

      .app-calendar-col {
        width: $app-calendar-col-width;
      }
    }

    &.no-summary {
      ::v-deep {
        .app-calendar-summary {
          display: none!important;
        }

        .app-calendar-summary {
          display: none!important;
        }

        .app-calendar-header-col {
          width: $app-calendar-col-width-no-summary;
        }

        .app-calendar-col {
          width: $app-calendar-col-width-no-summary;
        }
      }
    }
  }

  .app-calendar {
    & > div {
      min-width: 1300px;
    }

    .dashboard-user {
      color: var(--v-black-base);
      background: var(--v-white-base);
      height: 40px;
      position: sticky;
      top: 0;
      z-index: 6;
      border-top: 2px solid $color-line;

      & > div {
        position: sticky;
        left: 0;
        min-width: 320px;
        max-width: 95vw;
        width: 100%;
      }
    }

    .dashboard-user--dense {
      & > div {
        max-width: calc(95vw - 256px);
      }
    }
  }

  .dashboard-current-week {
    max-height: 20px;
    line-height: 1;
    position: absolute;
  }
</style>

<style lang="scss">
  .member-calendar {
    overflow-y: scroll;

    .app-calendar-header {
      top: 40px;
    }

    .app-calendar-column__wrapper {
      top: 40px + $app-calendar-header-height * 1;
    }

    .app-calendar-summary__wrapper {
      top: 40px + $app-calendar-header-height * 1;
    }

    .app-calendar-summary__body {
      top: 48px + $app-calendar-header-height * 2;
    }
  }
</style>
